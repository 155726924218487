.login-container {
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.privacy {
    font-size: 11px;
    padding: 20px;
}

.login-container legend {
    padding: 10px 0px;
}
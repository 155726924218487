.topics {
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.topic > label {
    display: flex;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 10px;
    margin-bottom: 10px;
}

.topics li:before {
    color: rgba(255, 255, 255, 0.3);
    transform: scale(1.5, 2);
    content: 'L';
    position: absolute;
    margin-left: -20px;

}

.topics > ul > li:before {
    content: none;
}

.topics ul {
    list-style-type: none;
    position: relative;
}

.topics input[type=checkbox] {
    margin-left: 10px;
}
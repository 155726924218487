
.image-container {
    display: grid;
    background-color: white;
    border-radius: 5px;
}

.background, .foreground {
    grid-area: 1 / 1 / 1 /1;
    z-index: 2;
}

.background {
    /*opacity: 50%;*/
    filter:brightness(50%);
    filter: saturate(50%);
    opacity: 50%;
    z-index: 1;
}

.image-container > div.foreground {
    filter: drop-shadow(1px 1px 5px rgba(0,0,0,0.7)) 
            drop-shadow(-1px -1px 5px rgba(0,0,0,0.7));
}

img {
    /*min-width: 300px;*/
    max-width: 100%;
}
.matching-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px 10px;
    margin: 5px 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
}

.matching-row > div {
    padding: 20px;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.matching-options-container  {
    display: flex;
    /*flex-direction: column;*/
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
}

.matching-options-container > div, .matching-options-container > div > label {
    display: flex;
    gap: 10px;
    align-items: center;
}

.matching-options-container > div {
    width: max(300px, calc(50% - 10px));
}
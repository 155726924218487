html, body, #root, #app {
  margin: 0px;
  padding: 0px;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-color: black;
  color: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

main {
  margin: 20px 0px;
  min-width: fit-content;
}

#app {
  min-width: fit-content;
}

header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px 20px;
}

footer {
  width: 100%;
  margin-top: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

footer > div {
  white-space: pre;
}

footer a {
  color: inherit;
}

header > nav {
  display: flex;
  align-items: center;
}

header > nav > div {
  flex: 1;
}

header > nav > div:nth-child(2) {
  flex: none;
}

header > nav > div:last-child {
  text-align: right;
}

.hamburger {
  font-size: 30px;
  line-height: 30px;
  margin-top: -8px;
}

nav a {
  text-decoration: none;
  color: inherit;
}

nav a:hover {
  color: white;
  font-weight: bold;
}

.title {
  font-weight: bold;
}
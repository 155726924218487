.multiple-choice-option {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    max-width: 400px;
    /*min-width: 200px;*/
    display: flex;
    align-items: center;
    gap: 10px;
}

.multiple-choice-option > label {
    display: flex;
    gap: 10px;
    align-items: center;
}

.multiple-choice-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-evenly;
}

/*.multiple-choice-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.multiple-choice-option {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multiple-choice-option > label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.multiple-choice-option input[type=checkbox] {
    margin-right: 10px;
}*/
legend, fieldset {
    border: none;
    margin: 0px;
    padding: 0px;
    margin-inline: 0px;
    padding-block: 0px;
    padding-inline: 0px;
}

.question-legend {
    margin: 20px 0px;
    padding: 0px;
    border: none;
    padding-inline: 0px;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}

.question-text {
    padding: 20px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

.question {
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #555;
    min-width: fit-content;
}

.question > form > fieldset {
    border: none;
    margin: 0px;
    padding: 0px;
    margin-inline: 0px;
    padding-block: 0px;
    padding-inline: 0px;
    display: flex;
    flex-direction: column;
}

.blank {
    color: rgba(255,255,255,0.5);
    border-bottom: 1px solid rgba(255,255,255,0.5);
    padding: 0px 20px;
}

.blank:before {
    content: '{';
}

.blank::after {
    content: '}';
}

.buttons-container {
    margin: 40px 0px;
    display: flex;
    justify-content: space-evenly;
}

.answer {
    font-weight: bold;
    min-width: 25px;
}

.correct {
    color: rgb(0,255,0);
}

.incorrect {
    color: rgb(255,0,0);
}

.missed {
    /*color: black;
    -webkit-text-stroke: 1px rgb(255,255,0);
    font-weight: bold;*/
    color: rgb(255,255,0);
}

input[type=submit], button {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border-radius: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 10px 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-weight: bold;
}

input[type=submit]:not(:disabled):hover, button:not(:disabled):hover {
    border: 1px solid rgba(255, 255, 255, 0.7);
}

input[type=submit]:disabled, button:disabled {
    color: rgba(255, 255, 255, 0.3);
}
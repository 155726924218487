.dashboard {
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #555;
    min-width: fit-content;
}

.dashboard > section {
    border-bottom: 1px solid #555;
    padding: 10px 0px;
}
.dashboard > section:last-child {
    border-bottom: none;
}

.dashboard > section > h3 {
    text-align: center;
}

.topics-stats-list li:before {
    color: rgba(255, 255, 255, 0.3);
    transform: scale(1, 1.5);
    content: 'L';
    position: absolute;
    margin-left: -20px;

}

.topics-stats-list > ul > li:before {
    content: none;
}

.topics-stats-list ul {
    list-style-type: none;
    position: relative;
}

.dashboard ul {
    list-style-type: none;
}

.dashboard a {
    color: inherit;
    text-decoration: none;
}

.dashboard-answer-container {
    display: flex;
    gap: 10px;
}

.dashbaord-question-link {
    border: 2px solid  rgba(255, 255, 255, 0.7);
    height: 20px;
    width: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

ul.stat {
    display: flex;
    margin-left: 0px;
    padding: 0px;
}

ul.stat > li {
    padding: 0px 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}

ul.stat > li:last-child {
    border-right: none;
}

.time {
    color: rgba(255, 255, 255, 0.3);
}